body{
    background-color: #FAFBFF;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    scrollbar-width: thin;

    @media (max-width: 1500px){
        font-size: 10px;
    }
    *{
        // zoom: .95;
    }

    a, input, button{
        font-family: 'Inter', sans-serif;
        outline: none;
    }

    .input-field{
        margin: 10px 0;
        width: 90%;
        min-width: 150px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;

        input, select{
            width: 100%;
            box-sizing: border-box;
            border: none;
            font-size: 1em;
            border-radius: 10px;
            padding: 12px 18px;
            background-color: #F9FBFF;
            outline: none;
            color: #000;
        }

        span{
            color: #000;
            font-weight: 500;
            font-size: 1em;
            margin-bottom: 10px;
        }
    }

    #widget-analytics{
        margin-top: 30px;
    }

    .error-field{
        span{
            color: red;
        }

        input, select{
            background-color: rgba($color: red, $alpha: 0.15);
            // border: red solid 2px;
        }
    }

    #data-base-pop-up-table{
        width: 100%;
        margin: 0;
        padding: 0;

        td{
            padding: 0;
            margin: 0;
        }

        tr{
            text-align: center;
            padding: 10px;
        }

        .input-field{
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;

            span{
                opacity: .8;
            }
        }
        // display: flex;
        // flex-flow: row nowrap;
        // justify-content: center;
        // align-items: center;
    }

    .widget, .popup-content{
        background-color: #fff;
        -webkit-box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
        -moz-box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
        box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
        border-radius: 30px;
    }

    .btn{
        cursor: pointer;
        margin-left: 50px;
        background-color: #1576C7;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-size: .9em;
        font-weight: 500;
        padding: 12px 24px;
    }

    .table{
        width: 100%;
        margin: 40px 0;
        // height: 60vh;
        box-sizing: border-box;
        padding: 30px;
        overflow-x: auto;
        overflow-y: auto;
        scrollbar-width: thin;
        // height: calc(100vh - 15vh - 7vh - 160px);

        .not-data-table{
            
            margin: auto;
            font-weight: 500;
        }

        .table-button{
            border: none;
            background-color: rgba(0, 0, 0, 0);
            cursor: pointer;

            img{
                width: 24px;
                height: 24px;
            }
        }

        table{
            width: 100%;
        }

        .headers{
            font-size: 1em;
            font-weight: 400;
            color: #B5B7C0;
        }

        tr{
            text-align: start;
            // text-align: center;
        }
        
        td{
            border-bottom: 1px solid #EEEEEE;
            border-collapse: collapse;
            margin: 1px;
            padding: 15px 5px;
            font-weight: 500;
            font-size: .8em;
        }

        // tr:last-child td:last-child{
            // border: none;
        // }
    }

    .pop-up{
        padding: 30px;

        .close{
            cursor: pointer;
        }

        .content{
            width: 100%;
            height: 100%;
        }

        .buttons{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;

            .btn{
                margin: 0;
            }

            .close-button{
                margin-left: 20px;
                background-color: #C1C1C1;
            }
        }
        .header{
            // padding: 10px 0;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            h2{
                margin: 0;
                padding: 0;
                font-size: 1.3em;
                font-weight: 500;
            }

            button{
                background-color: rgba(0, 0, 0, 0);
                border: none;

                img{
                    width: 14px;
                    height: 14px;
                }
            }
        }

        hr{
            width: 100%;
            margin: 30px 0;
            border: none;
            opacity: .7;
            color: #EEEEEE;
            background-color: #EEEEEE;
            height: 1px;
        }
    }

    .popup-content{
        border-radius: 30px;
        box-shadow: none;
    }

    .popup-overlay{
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: none;
    }

    .menu{
        width: 30%;
        height: calc(100%);
        max-width: 350px;
        min-width: 350px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
        background-color: #fff;
        -webkit-box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
        -moz-box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);
        box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.5);

        nav{
            padding-top: 30px;

            .link-icon{
                width: 24px;
            }

            .link-chevron{
                width: 16px;
            }

            .link{
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
            }

            ol{
                margin: 0;
                padding: 0;
                width: 100%;
                text-decoration: none;
                list-style: none;

                li{ 
                    margin-top: 20px;
                    padding: 12px;
                    box-sizing: border-box;
                    width: 100%;
                    border-radius: 8px;

                    span{
                        // margin-left: -30%;
                    }

                    a{
    
                        // margin-right: 80%;
                        text-decoration: none;
                        color: #9197B3;
                    }
                }
            }    
        }

        .active{
            a{
                color: #fff;
            }
            background-color: #1576C7;
        }

        .top, .bottom{
            width: 100%;

            .logo{
                img{
                    max-width: 200px;
                    width: 100%;
                }
            }
        }
        .bottom{
            display: flex;
            justify-content: center;
            align-items: center;

            .logout-button{
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0);
                border: none;
                color: red;
                font-size: .9em;
            }
        }
    }

    .page{
        width: 100%;
        height: 100%;
        display: flex;
        overflow: auto;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        .page-content{
            width: 100%;
            height: 100%;
            padding: 30px;
            box-sizing: border-box;

            .header{
                // height: 6vh;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                padding: 30px 0px;
                color: #B5B7C0;
                font-weight: 300;

                h2{
                    font-weight: 500;
                    margin: 0;
                    padding: 0;
                }

                button{
                    cursor: pointer;
                    margin-left: 50px;
                    background-color: #1576C7;
                    border: none;
                    border-radius: 4px;
                    color: #fff;
                    font-size: .9em;
                    font-weight: 500;
                    padding: 12px 24px;
                }
            }
        }
    }

    #data-base{
        
    }

    #auth{

        form{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            padding: 30px;
            width: 350px;

            .invalid-data{
                box-sizing: border-box;
                padding: 10px;
                border-radius: 10px;
                color: #fff;
                opacity: .6;
                margin: 10px 0;
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                background-color: red;
            }
            
            h2{
                font-weight: 300;
                width: 100%;
                margin: 0;
                padding: 0;
                text-align: start;
                margin-bottom: 20px;
                opacity: .5;
            }

            #send-btn{
                width: 30%;
                margin: 0;
                margin-top: 20px;
            }

        }

    }
    
    #analytics{

        .widget{
            box-sizing: border-box;
            // padding: 30px;
            background-color: #fff;
            opacity: 1;
        }

        .table{
            margin-top: 0;
            padding-top: 0px;
            box-shadow: none;
        }

        .analytics-data{
            display: flex;
            width: 100%;
            // height: 15vh;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            hr{
                height: 50px;
                width: 3px;
                border: none;
                color: #F0F0F0;
                background-color: #F0F0F0;
            }

            .item{
                padding: 15px;
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                align-items: center;
                justify-content: center;
                
                .icon{
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    width: 4vw;
                    height: 4vw;
                    min-width: 50px;
                    min-height: 50px;

                    img{
                        width: 50%;
                        max-width: 26px;
                    }
                }

                #close-icon{
                    background-color: #9197B3;
                }
                #door-icon{
                    background-color: #CDE8FF;
                }
                #lock-icon{
                    background-color: #D9D9D9;
                }
                #geo-icon{
                    background-color: #1576C7;
                }

                .data{
                    margin-left: 7%;
                    height: 100%;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    .name{
                        font-size: .8em;
                        font-weight: 400;
                        // opacity: .5;
                        color: #ACACAC;
                    }

                    .value{
                        margin-top: 3px;
                        font-size: 1.8em;
                        font-weight: 600;
                    }
                }
            }
        }

        .analytics-filter{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 30px;
            padding-bottom: 0;
            margin-bottom: 0;
            box-sizing: border-box;

            form{
                display: flex;
                flex-flow: column;
                justify-content: flex-start;
                align-items: flex-start;
                // background-color: red;
                .top-form, .bottom-form{
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;
                }
                .bottom-form{
                    margin-top: 20px;
                }
                .bottom-form-container {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: space-between;
                }
                .input-field{
                    margin-right: 8px;

                    span{
                        opacity: .5;
                        font-size: .8em;
                    }
                }
            }
        }
    }
    .td-horizontal{
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        display: flex;
        padding-right: 20px;
        // background-color: red;
        span{
            max-width: 100px;
        }
        .input-field {
            width: 100%;
            box-sizing: border-box;
            input{
                width: 95%;
            }
        }
    }
    #analytics-pop-up-table {
        td, tr{
            box-sizing: border-box;
        }
    }
}